<template>
  <div class="sld_myPoint_wrapper">
    <MemberTitle memberTitle="礼品卡" style="padding-left:20px"></MemberTitle>
    <div class="pointCon">
      <div class="jifen_top">
        <!-- <div class="itg">
          <span>礼品卡余额</span>
          <span class="colr">{{ pointAva }}</span>
        </div> -->
        <div class="bindGift" @click="toBindGiftCard">绑定礼品卡</div>
      </div>

      <div class="content_tit">
        <ul class="tabsTitle">
          <li :class="{ current: indexNum == 0 }" id="all" @click="handleSwitch(0)">可用卡</li>
          <li :class="{ current: indexNum == 1 }" id="income" @click="handleSwitch(1)">不可用卡</li>
        </ul>
      </div>
      <!-- 礼品卡列表 -->
      <div class="gift_list flex_row_start_center">
        <div class="gift_item" :class="indexNum == 1 ? 'gift_not_use' : ''" v-for="(item, index) in pointList.list" :key="index">
          <div class="gift_top flex_row_between_center">
            <div class="gift_top_left">面值：{{item.amountLimit.toFixed(2)}}元</div>
            <div class="gift_top_right flex_row_start_center" @click="handleShowConsumptionRecords(item)"><img
                src="@/assets/member/gift_top_icon.png" alt=""> 消费记录
            </div>
          </div>
          <div class="gift_center flex_row_center_center">
            <div class="price">余额 ￥<span class="price_int">{{item.amount.toFixed(2)}}</span></div>
          </div>
          <div class="gift_bottom">
            <div class="gift_time">{{item.bindTime}}-{{item.effectiveEnd}}</div>
            <div class="gift_use flex_row_start_center" >{{item.useType==1?'全部商品可用':'指定商品可用'}}  
                <img
                src="@/assets/member/gift_info_icon.png" alt=""
                @click="handleShowCanUseGoods(item)" v-if="item.useType != 1"
                >
                </div>
          </div>

          <img class="gift_not_use_icon" v-if="indexNum == 1 && item.state == 2" src="@/assets/member/gift_used.png" alt="">
          <img class="gift_not_use_icon" v-if="indexNum == 1 && item.state == 3" src="@/assets/member/gift_not_used.png" alt="">
        </div>
      </div>
      <div class="detail_none flex_row_center_center"
            v-if="pointList.list.length==0">
            <img :src="noneUrl" style="width: 100px;margin-bottom: 15px;">
            <p>{{L['暂无数据']}}</p>
        </div>
      <div class="flex_row_end_center sld_pagination">
        <!-- <el-pagination @current-change="handleCurrentChange" v-model:currentPage="pageData.page.current"
          :page-size="pageData.page.pageSize" layout="prev, pager, next, jumper" :total="pageData.page.total"
          :hide-on-single-page="true">
        </el-pagination> -->
      </div>
    </div>

    <!-- 消费记录弹窗 -->
    <el-dialog custom-class="consumption_records" v-model="consumptionRecordsShow" title="消费记录" width="503px"
      :before-close="handleClose">
      <div class="record_content" >
        <div class="card">卡号：{{cartId}}</div>
        <div class="record_list">
          <div class="record_item flex_row_between_center" v-for="(item, index) in giftLog.list" :key="index">
            <div class="record_item_left">
              <div class="order">{{item.logTypeValue}}-订单号{{item.orderSn}}</div>
              <div class="time">{{item.logTime}}</div>
            </div>
            <div class="record_item_right flex_column_center_end">
              <div class="price" :class="item.logType==1 ? '' : 'active_price'">{{item.logType==1?'-':'+'}}{{item.changeValue.toFixed(2)}}</div>
              <div class="amount">余额：{{item.afterChangeAmount.toFixed(2)}}</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 可用商品弹窗 -->
    <el-dialog custom-class="can_use_goods" v-model="canUseGoodShow" title="可用商品" width="503px"
      :before-close="handleClose">
      <div class="can_use_content">
        <div class="can_use_list">
          <div class="can_use_item flex_row_start_center" v-for="(item, index) in goods_list.list" :key="index">
            <img class="goods_img" :src="item.mainImage" alt="">
            <div class="item_right flex_column_between_start">
              <div class="goods_name">{{item.goodsName}}</div>
              <div class="goods_price">￥{{item.goodsPrice.toFixed(2)}}</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {  ElMessage } from "element-plus";
import MemberTitle from '@/components/MemberTitle';
import SldCommonEmpty from '@/components/SldCommonEmpty';
import { reactive, onMounted, getCurrentInstance, ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'giftPoint',
  components: {
    MemberTitle,
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const L = proxy.$getCurLanguage()
    const router = useRouter()
    const pointAva = ref(0)
    const pointList = reactive({ list: [] })
    const giftLog = reactive({ list: [] })
    const goods_list = reactive({ list: [] })
    const noneUrl = require('@/assets/common_empty.png');
    const cartId = ref('')
    const pageData = reactive({ page: {} })
    const indexNum = ref(0)
    const params = reactive({
      current: 1,
      pageSize: 10,
      state: 1
    })
    const consumptionRecordsShow = ref(false) //记录弹窗是否显示
    const canUseGoodShow = ref(false) //商品弹窗
    const getInitPoint = () => {
      proxy.$get('/v3/promotion/front/gift/giftCardAmount').then(res => {
        if (res.state == 200) {
          pointAva.value = res.data
        }
      })
    }
    const getPointList = () => {
      proxy.$get('/v3/promotion/front/gift/list', params).then(res => {
        if (res.state == 200) {
          pointList.list = res.data
        }
      })
    }

    const handleSwitch = (index) => {
      indexNum.value = index
      params.state = index+1
      params.current = 1
      getPointList()
    }
    const handlePrevCilickChange = (e) => {
      params.current = e
      getPointList()
    }

    const handleNextCilickChange = (e) => {
      params.current = e
      getPointList()
    }

    const handleCurrentChange = (e) => {
      params.current = e
      getPointList()
    }

    const handleShowConsumptionRecords = (val) => {
        cartId.value = val.cartId
       proxy.$get('v3/promotion/front/gift/giftLog', {detailId:val.detailId}).then(res => {
        if (res.state == 200) {
          giftLog.list = res.data
           consumptionRecordsShow.value = true
        }else {
            ElMessage.warning(res.msg)
        }
      })
     
    }

    const handleShowCanUseGoods = (val) => {
         proxy.$get('v3/promotion/front/gift/bindGoods', {giftId:val.giftId}).then(res => {
        if (res.state == 200) {
          goods_list.list = res.data.list
           canUseGoodShow.value = true
        }else {
            ElMessage.warning(res.msg)
        }
      })
      
    }

    // 去绑定礼品卡
    const toBindGiftCard = () => {
      router.push('/member/gift/bindGiftCard')
    }

    onMounted(() => {
      getInitPoint()
      getPointList()
    })

    return {
      L,
      pointAva,
      pointList,
      pageData,
      handlePrevCilickChange,
      handleNextCilickChange,
      handleCurrentChange,
      handleSwitch,
      indexNum,
      consumptionRecordsShow,
      canUseGoodShow,
      handleShowConsumptionRecords,
      handleShowCanUseGoods,
      toBindGiftCard,
      giftLog,
      cartId,
      goods_list,
      noneUrl
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/member/gift.scss';
@import '@/style/base.scss';

.sld_pagination {
  margin-right: 40px;
  margin-bottom: 20px;
}
.detail_none {
        flex-direction: column;
        margin-top: 7px;
        width: inherit;
        height: 200px;
    }

    .detail_none p {
        padding: 7px;
    }
</style>